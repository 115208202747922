import * as jschardet from 'jschardet';

export const readFile = (blob: Blob): Promise<string> => {
    return new Promise<string>((resolve, reject) => {
        const encodingReader = new FileReader();

        encodingReader.onload = () => {
            const arrayBuffer = encodingReader.result as ArrayBuffer;
            const binaryContent = new Uint8Array(arrayBuffer);
            const binaryString = Array.from(binaryContent)
                .map((byte) => String.fromCharCode(byte))
                .join(''); 

            const enc = jschardet.detect(binaryString); 
            let detectedEncoding = 'ISO-8859-1';
            if (enc && typeof enc.encoding === 'string' && enc.encoding.toLocaleLowerCase() === 'utf-8') {
                detectedEncoding = enc.encoding;
            }
            const textReader = new FileReader();
            textReader.onload = () => {
                resolve(textReader.result as string);
            };
            textReader.onerror = () => {
                reject(textReader.error);
            };
            textReader.readAsText(blob, detectedEncoding);
        };

        encodingReader.onerror = () => {
            reject(encodingReader.error);
        };

        encodingReader.readAsArrayBuffer(blob); 
    });
};