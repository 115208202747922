import { ILanguageConfig } from '../../interface/i-language-config';
import { AppointmentService, AppointmentStatusEnum } from './../../../resource/page/appointment/appointment.sevice';
import { Component, Output, EventEmitter, OnInit, Input } from '@angular/core';

@Component({
	selector: 'app-appointment-status-list',
	templateUrl: './appointment-status-list.component.html',
	styleUrls: ['./appointment-status-list.component.scss']
})
export class AppointmentStatusListComponent implements OnInit, ILanguageConfig {
	@Output() onChange = new EventEmitter();
	@Input() array: { index: number, id: string, checked: boolean, disabled: boolean, color: string, count: number, status: string }[] = [];

	iconDir = './../../../../../assets/icon/';
	imgArrowLeftOff = this.iconDir + 'arrow_circle_left_off.svg';
	imgArrowLeftOn = this.iconDir + 'arrow_circle_left_on.svg';
	imgArrowRightOff = this.iconDir + 'arrow_circle_right_off.svg';
	imgArrowRightOn = this.iconDir + 'arrow_circle_right_on.svg';
	_languageData;
	_responsiveOptions = [{
			breakpoint: '1024px',
			numVisible: 6,
			numScroll: 3
		}, {
			breakpoint: '768px',
			numVisible: 3,
			numScroll: 2
		}, {
			breakpoint: '560px',
			numVisible: 2,
			numScroll: 1
	}];

	constructor() { }

	ngOnInit() {
		this.getLanguageFile();
		this.fakeArray();
		this.uncheckAll();
		this.check(0);
		this.onChange.emit({status: this.array[0].id, checked: true});
	}

	fakeArray() {
		this.array = [
			{ index: 0, id: AppointmentStatusEnum.PendingExport, checked: false, disabled: false, color: AppointmentService.getAppointmentStatusColor(AppointmentStatusEnum.PendingExport), count: 50, status: this._languageData.pendingExportAppointments},
			{ index: 1, id: AppointmentStatusEnum.CreatedExport, checked: false, disabled: false, color: AppointmentService.getAppointmentStatusColor(AppointmentStatusEnum.CreatedExport), count: 70, status: this._languageData.createdExportAppointments},
			{ index: 2, id: AppointmentStatusEnum.ReservedExport, checked: false, disabled: false, color: AppointmentService.getAppointmentStatusColor(AppointmentStatusEnum.ReservedExport), count: 25, status: this._languageData.reservedExportAppointments},
			{ index: 3, id: AppointmentStatusEnum.PendingImport, checked: false, disabled: false, color: AppointmentService.getAppointmentStatusColor(AppointmentStatusEnum.PendingImport), count: 21, status: this._languageData.pendingImportAppointments},
			{ index: 4, id: AppointmentStatusEnum.CreatedImport, checked: false, disabled: false, color: AppointmentService.getAppointmentStatusColor(AppointmentStatusEnum.CreatedImport), count: 30, status: this._languageData.createdImportAppointments},
			{ index: 5, id: AppointmentStatusEnum.ReservedImport, checked: false, disabled: false, color: AppointmentService.getAppointmentStatusColor(AppointmentStatusEnum.ReservedImport), count: 55, status: this._languageData.reservedImportAppointments},
			{ index: 6, id: AppointmentStatusEnum.ReservedImport, checked: false, disabled: false, color: AppointmentService.getAppointmentStatusColor(AppointmentStatusEnum.ReservedImport), count: 55, status: this._languageData.reservedImportAppointments},
			{ index: 7, id: AppointmentStatusEnum.ReservedImport, checked: false, disabled: false, color: AppointmentService.getAppointmentStatusColor(AppointmentStatusEnum.ReservedImport), count: 55, status: this._languageData.reservedImportAppointments},
			{ index: 8, id: AppointmentStatusEnum.ReservedImport, checked: false, disabled: false, color: AppointmentService.getAppointmentStatusColor(AppointmentStatusEnum.ReservedImport), count: 55, status: this._languageData.reservedImportAppointments},
			{ index: 9, id: AppointmentStatusEnum.ReservedImport, checked: false, disabled: false, color: AppointmentService.getAppointmentStatusColor(AppointmentStatusEnum.ReservedImport), count: 55, status: this._languageData.reservedImportAppointments},
			{ index: 10, id: AppointmentStatusEnum.ReservedImport, checked: false, disabled: false, color: AppointmentService.getAppointmentStatusColor(AppointmentStatusEnum.ReservedImport), count: 55, status: this._languageData.reservedImportAppointments},
		];
	}

	getLanguageFile() {
		this._languageData = require('./language.json').en;
	}

	onSwichChange(changes) {
		this.uncheckAll();
		if (changes.checked) {
			this.check(changes.index);
		}
		if (changes && this.array && this.array[changes.index]) {
			this.onChange.emit({status: this.array[changes.index].id, checked: changes.checked});
		}
	}

	disableAll() {
		this.array.forEach((value, index) => {
			this.disable(index);
		});
	}

	disable(index: number) {
		if (this.array && this.array[index]) {
			this.array[index].disabled = true;
			this.array[index].checked = false;
		}
	}

	uncheckAll() {
		if (this.array) {
			for (const item of this.array) {
				item.checked = false;
			}
		}
	}

	check(index: number) {
		this.uncheckAll();
		if (this.array && this.array[index]) {
			this.array[index].disabled = false;
			this.array[index].checked = true;
		}
	}
}