import {Component, forwardRef, HostListener, Input} from '@angular/core';
import {Icons} from '../../../../icons';
import {Labels} from '../../../../internationalization/labels/labels';
import {ControlValueAccessor, NG_VALUE_ACCESSOR} from '@angular/forms';

@Component({
  selector: 'app-upload-file-reactive',
  templateUrl: './upload-file-reactive.component.html',
  styleUrls: ['./upload-file-reactive.component.scss'],
	providers: [
		{
			provide: NG_VALUE_ACCESSOR,
			useExisting: forwardRef(() => UploadFileReactiveComponent),
			multi: true
		}
	]
})
export class UploadFileReactiveComponent  implements ControlValueAccessor {

	_icons = Icons;
	_labels = Labels.getLabels();
	file: File;
	inputId: string = 'input-file-' + Math.random().toString();

	@Input() disabled: boolean = false;
	// Input File Name
	@Input() fileName: string;
	// Input File Type
	@Input() typeFile: string;
	// File types the input will accept. You can send more than one by separating it with a comma. Ex: .xls,.jpg,.csv
	@Input() accept: string;
	// Input select one more one file
	@Input() multiple: boolean = true;
	// Boolean to show or not show icon attachment_no
	@Input() csvIsValid: boolean = true;

	fileAttached = false;

	@HostListener('input', ['$event.target.files']) emitFiles( fileList: FileList ) {
		const copy = Object.assign({}, fileList);
		this.onChange(copy);
		this.file = fileList.item(0);
		this.fileAttached = true;
		(document.getElementById(this.inputId) as any).value = "";
	}

	// start ControlValueAccessor methods
	onChange: any = (value) => {}
	onTouch: any = () => {}
	registerOnChange(fn: any): void {
		this.onChange = fn;
	}
	registerOnTouched(fn: any): void {
		this.onTouch = fn;
	}
	writeValue(value: string) {
		// clear file input
		const input = (document.getElementById(this.inputId) as any);
		if (input) {
			input.value = "";
		}
		this.file = null;
		this.fileAttached = false;
	}
	setDisabledState(isDisabled: boolean) {
		this.disabled = isDisabled;
	}
	// end ControlValueAccessor methods

	formatBytes(decimals = 2) {
		if (!this.file) return '0 Bytes';
		let bytes = this.file.size;
		const k = 1024;
		const dm = decimals < 0 ? 0 : decimals;
		const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
		const i = Math.floor(Math.log(bytes) / Math.log(k));
		return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
	}

}
