import { ILanguageConfig } from '../../interface/i-language-config';
import { ContainerStatusEnum } from './../../../resource/page/appointment/appointment.sevice';
import { Component, OnInit } from '@angular/core';

@Component({
	selector: 'app-popover-containers-status',
	templateUrl: './popover-containers-status.component.html',
	styleUrls: ['./popover-containers-status.component.scss']
})
export class PopoverContainersStatusComponent implements OnInit, ILanguageConfig {
	_languageData;

	constructor() { }

	get appointmentStatusEnum() { return ContainerStatusEnum; }

	ngOnInit() {
		this.getLanguageFile();
	}

	getLanguageFile() {
		this._languageData = require('./language.json').en;
	}
}