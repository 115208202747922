import qa from './utils/qa';
import cdt from './utils/cdt';
import uat from './utils/uat';
import prod from './utils/prod';
import local from './utils/local';
import preprod from './utils/preprod';

import { Constants } from 'src/constants';

export const eytUriPattern = 'apiazewtanli001web';
export const uatUriPattern = 'uat.alianca.com.br';
export const preProdUriPattern = 'intercab-pp.alianca.com.br';
export const prodUriPattern = 'www.alianca.com.br';

export type IntercabFrontEnvironment = {
	redirectUri: string;
	apiUri: string;
	fileReference: string;
	ambient: keyof typeof Constants.constant.ambient;
	version: string;
	consumerkey: string;
	instrumentationKey: string;
	azfUri: string;
	commercialUri: string;
	postponeUri?: string;
};

export const getLocation: IntercabFrontEnvironment = (function () {
	const environments = [cdt, qa, prod, preprod, uat, local];

	return environments.find((environment) =>
		environment.redirectUri.includes(window.location.host)
	);
})();

export const location = getLocation;