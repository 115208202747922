import {Component,ElementRef,forwardRef,HostListener,ViewChild} from '@angular/core';
import {NG_VALUE_ACCESSOR} from '@angular/forms';
import {UploadFileReactiveComponent} from '../upload-file-reactive.component';
import {convertXlsxFileToCsvFile} from '../../../../shared/helpers/file/csv.util';


@Component({
  selector: 'app-upload-xlsx-as-csv-file-reactive',
  templateUrl: '../upload-file-reactive.component.html',
  styleUrls: ['../upload-file-reactive.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => UploadXlsxAsCsvFileReactiveComponent),
      multi: true
    }
  ]
})
export class UploadXlsxAsCsvFileReactiveComponent extends UploadFileReactiveComponent {
  readonly typeFile: string = 'xlsx';
  readonly accept: string = '.xlsx';
  @ViewChild('inputRef', { static: true }) inputFile: ElementRef;

  @HostListener('input', ['$event.target.files']) async emitFiles(fileList: FileList) {
    const processedFileList = await this.createFileList(fileList);
    this.updateFileState(processedFileList);
    this.emitChangeEvent();
    this.clearInputValue();
  }

  async createFileList(fileList: FileList): Promise<FileList> {
    const dataTransfer = new DataTransfer();
    const promises = Array.from(fileList).map(async (file) => {
      const newFile = await this.generateNewCsvFileWithBackendCompatibility(file);
      dataTransfer.items.add(newFile);
    });
    await Promise.all(promises);
    return dataTransfer.files;
  }

  async generateNewCsvFileWithBackendCompatibility(inputFile: File): Promise<File> {
      return convertXlsxFileToCsvFile(inputFile)
  }

  updateFileState(fileList: FileList): void {
    this.file = fileList.item(0);
    this.fileAttached = true;
    this.onChange({...fileList});
  }

  clearInputValue(): void {
    (document.getElementById(this.inputId) as any).value = '';
  }

  emitChangeEvent(): void {
    const event = new Event('change', {bubbles: true});
    this.inputFile.nativeElement.dispatchEvent(event);
  }
}