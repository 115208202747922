import {Component} from '@angular/core';
import {saveAs} from 'file-saver';
import {DownloadFileComponent} from '../download-file.component';
import {convertCsvBlobToXlsx} from 'src/app/shared/helpers/file/csv.util';


@Component({
  selector: 'app-download-csv-as-xlsx-file',
  templateUrl: '../download-file.component.html',
  styleUrls: ['../download-file.component.scss']
})
export class DownloadCsvAsXlsxFileComponent extends DownloadFileComponent {
  
  readonly typeFile = 'csv'

  async generateXlsxFile(inputBlob: Blob): Promise<void> {
    const xlsxFile = await convertCsvBlobToXlsx(inputBlob, this.fileName);
    saveAs(xlsxFile);
  }
  download() {
    if (this.file) {
      this.generateXlsxFile(this.file);
    } else {
      this.caseUri();
    }
    if (this.listenClick) {
      this.downloaded = true;
      this.wasDownloaded.emit();
    }
  }
}