import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {UploadFileReactiveComponent} from './upload-file-reactive.component';
import {ReactiveFormsModule} from '@angular/forms';
import {UploadXlsxAsCsvFileReactiveComponent} from './upload-csv-file-reactive/upload-csv-file-reactive.component';


@NgModule({
  declarations: [UploadFileReactiveComponent, UploadXlsxAsCsvFileReactiveComponent],
	imports: [CommonModule, ReactiveFormsModule],
	exports: [UploadFileReactiveComponent, UploadXlsxAsCsvFileReactiveComponent]
})
export class UploadFileReactiveModule { }
