import { Directive, Input } from '@angular/core';
import { IResettableTab } from '../interface/i-resettable-tab';

@Directive({
	selector: '[appResettableForm]'
})
export class ResettableFormDirective implements IResettableTab {
	@Input() tab;
	@Input() form: IResettableTab;

	constructor() { }

	reset() {
		this.form.reset();
	}
}