import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {MasterComponent} from './master/master.component';
import {SharedModule} from 'src/app/shared/shared.module';
import {ModalComponent} from './modal/modal.component';
import {ToastComponent} from './toast/toast.component';
import {ConfirmComponent} from './confirm/confirm.component';
import {DirectiveModule} from '../directive/directive.module';
import {SettingsMenuComponent} from './settings-menu/settings-menu.component';
import {ComponentModule} from '../component/component.module';
import {SidebarComponent} from './sidebar/sidebar.component';
import {AcrConfirmComponent} from 'src/app/resource/page/product/vessel-capacity/acr/acr-confirm-modal/acr-confirm/acr-confirm.component';
import {ContentModalComponent} from './content-modal/content-modal.component';

@NgModule({
	declarations: [
		MasterComponent,
		ModalComponent,
		ToastComponent,
		ConfirmComponent,
		SettingsMenuComponent,
		SidebarComponent,
		AcrConfirmComponent,
		ContentModalComponent,
	],
	imports: [
		CommonModule,
		SharedModule,
		DirectiveModule,
		ComponentModule,

	],
	exports: [
		MasterComponent,
		ModalComponent,
		ToastComponent,
		ConfirmComponent,
		SettingsMenuComponent,
		SidebarComponent,
		AcrConfirmComponent,
		ContentModalComponent,
	],
	entryComponents: [
		ToastComponent,
		ConfirmComponent,
		AcrConfirmComponent,
		SidebarComponent,
		ContentModalComponent,
	],
	providers: []
})
export class LayoutModule {
}
