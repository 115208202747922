import {readFile} from "./read-file.util";
import Papa from "papaparse"
import * as ExcelJS from "exceljs";
import * as XLSX from 'xlsx';

export const removeHeaderMetaData = (csv: string): string =>{
	const regex = /\(\(info\)[^)]*\)/g;
	return csv.replace(regex, '').trim();
}
export function addInfoToHeaderString(info: string){
	return `((info) ${info})`;
}

export const  readCsvAndRemoveMetadata = async (blob: Blob): Promise<string> => {
	let csv = await readFile(blob);
	csv = removeCsvMetaData(csv);
	return csv;
}


export const removeCsvMetaData = (csv: string): string => {
  let cleanedCsv = csv.trim();

  if (cleanedCsv.charCodeAt(0) === 0xFEFF) {
    cleanedCsv = cleanedCsv.slice(1);
  }

  if (cleanedCsv.startsWith('sep=')) {
    const lineEndIndex = Math.min(
      cleanedCsv.indexOf('\n') !== -1 ? cleanedCsv.indexOf('\n') : Infinity,
      cleanedCsv.indexOf('\r') !== -1 ? cleanedCsv.indexOf('\r') : Infinity
    );
    if (lineEndIndex !== Infinity) {
      cleanedCsv = cleanedCsv.substring(lineEndIndex + 1);
    }
  }
  return cleanedCsv;
};

export const csvToJson = (csvText): unknown[] => {
	let cleanedCsv = removeCsvMetaData(csvText);
	cleanedCsv = removeHeaderMetaData(cleanedCsv);
	const parseResult = Papa.parse(cleanedCsv, {
	  skipEmptyLines: true,
	  header: true,
	  transform: (value) => value.trim(),
	  transformHeader: (header) => header.trim()
	});
  
	const validHeaders = parseResult.meta.fields.filter(
	  (header) => header && header.trim() !== ""
	);

	const cleanedData = parseResult.data.filter((row) =>
		Object.values(row).some((value: string) => value.trim() !== "")
	  );
	
	return cleanedData.map((row) => {
	const formattedRow = {};

	validHeaders.forEach((header) => {
	  const formattedKey = formatName(header) || header;
	  formattedRow[formattedKey] = row[header];
	});

	return formattedRow;
	});
  };

export async function convertCsvFileToXlsx(csvFile: File): Promise<File> {
	return convertCsvBlobToXlsx(csvFile, csvFile.name);
  }

export async function convertCsvBlobToXlsx(csvBlob: Blob, fileName: string): Promise<File> {
	const sheetName = fileName.replace('.csv', '');
	
	const csvData = await readFile(csvBlob);
	const cleanedCsv = removeCsvMetaData(csvData);
	const parsedData = Papa.parse(cleanedCsv, {
		skipEmptyLines: true,
		header: true,
		transform: (value) => value.trim().replace(/\r/g, ""),
		transformHeader: (header) => header.trim(),
	  });
	
	const validHeaders = parsedData.meta.fields.filter((header) => header && header.trim() !== '');
	const cleanedData = parsedData.data.map((row) => {
		const cleanedRow = {};
		validHeaders.forEach((header) => {
		  cleanedRow[header] = row[header];
		});
		return cleanedRow;
	  });
	return createXlsxFile(cleanedData, validHeaders, sheetName);
  }

  async function createXlsxFile(data: any[], headers: string[], sheetName: string): Promise<File> {
	const workbook = new ExcelJS.Workbook();
	const worksheet = workbook.addWorksheet(sheetName);
  
	
	const headerRow = worksheet.addRow(headers);
	headerRow.eachCell((cell) => {
		cell.font = { bold: true, color: { argb: 'FFFFFFFF' } }; 
		cell.fill = { type: 'pattern', pattern: 'solid', fgColor: { argb: 'FF0070C0' } };
		cell.alignment = { vertical: 'middle', horizontal: 'left' };
		cell.border = {
		  top: { style: 'thin' },
		  left: { style: 'thin' },
		  bottom: { style: 'thin' },
		  right: { style: 'thin' },
		};
	  });
	
	worksheet.columns.forEach(c => c.numFmt = '@'); 

	const rows = data.map((row) => headers.map((header) => {
		const hasRow = row[header]
		if(hasRow) {
			return row[header].toString() || ""
		}
		return ""
	}));
	worksheet.addRows(rows);

  
	worksheet.autoFilter = {
	  from: { row: 1, column: 1 },
	  to: { row: 1, column: headers.length },
	};
  
	const buffer = await workbook.xlsx.writeBuffer();
	return new File([buffer], `${sheetName}.xlsx`, {
	  type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
	});
  }

export const  convertXlsxFileToCsvFile = async (xlsxFile: File):  Promise<File> => {
	const fileReader = new FileReader();

	const excelData: ArrayBuffer = await new Promise<ArrayBuffer>((resolve, reject) => {
	  fileReader.onload = (event: any) => resolve(event.target.result as ArrayBuffer);
	  fileReader.onerror = () => reject(new Error('Cannot read XLSX'));
	  fileReader.readAsArrayBuffer(xlsxFile);
	});

	const workbook = XLSX.read(excelData, { type: 'array' });
	const firstSheetName = workbook.SheetNames[0];
	const worksheet = workbook.Sheets[firstSheetName];
	let csvContent = XLSX.utils.sheet_to_csv(worksheet, { FS: ';' });
	csvContent = removeHeaderMetaData(csvContent);
	const outputCsvFile = new File([csvContent], xlsxFile.name.replace(/\.(xls|xlsx)$/, '.csv'), {
	  type: 'text/csv',
	});
	return outputCsvFile;
}

export const csvHeaders = ((csv): any[] => {
	csv = removeCsvMetaData(csv);
	const cleanedCsv = removeCsvMetaData(csv);
	const parseResult = Papa.parse(cleanedCsv, {
	  skipEmptyLines: true,
	  preview: 1, 
	  delimiter: '',
	});
	if (parseResult.data.length === 0 || !Array.isArray(parseResult.data[0])) {
	  return []; 
	}
	return parseResult.data[0].map((header: string) => header.trim());
})

export const formatName = (name: string): string => {
	name = removeHeaderMetaData(name);
	let words = name.replace(/\r/g, "").replace(/%/g, "").trim().split(' ');
	if(words.length == 1) {
		words[0] = words[0].toLowerCase();
	}
	const formattedName = words.map((word, index) => {
		if (index === 0) {
			return word.toLowerCase();
		}
		return word.charAt(0).toUpperCase() + word.slice(1).toLowerCase();
	}).join('');
	return formattedName;
}