import { ILanguageConfig } from '../../interface/i-language-config';
import { ContainerStatusEnum } from './../../../resource/page/appointment/appointment.sevice';
import { Component, OnInit, Input } from '@angular/core';

@Component({
	selector: 'app-circle-text',
	templateUrl: './circle-text.component.html',
	styleUrls: ['./circle-text.component.scss']
})
export class CircleTextComponent implements OnInit, ILanguageConfig {
	@Input() count: number;
	@Input() abbreviation: string;
	@Input() abbreviationBackgroundColor: string;
	@Input() abbreviationColor: string;
	@Input() color: string;
	@Input() backgroundColor: string;
	@Input() borderColor: string;
	@Input() label: string;
	@Input() showLabel: boolean;
	@Input() appointmentStatusId: string;

	_appointmentStatusArray: { id: string, label: string, abbreviation: string, abbreviationBackgroundColor: string, abbreviationColor: string }[] = [];
	_languageData;

	constructor() { }

	ngOnInit() {
		this.getLanguageFile();
		this.fillAppointmentStatusArray();
		this.getLayoutBasedOnAppointmentStatusId();
	}

	getLanguageFile() {
		this._languageData = require('./language.json').en;
	}

	fillAppointmentStatusArray() {
		this._appointmentStatusArray = [
		{ id: ContainerStatusEnum.Pending, label: this._languageData.pendingAppointment, abbreviation: this._languageData.pendingAppointmentAbbreviation, abbreviationBackgroundColor: '#ffdd1c', abbreviationColor: '#000' },
		{ id: ContainerStatusEnum.Confirmed, label: this._languageData.confirmed, abbreviation: this._languageData.confirmedAbbreviation, abbreviationBackgroundColor: '#5ebcde', abbreviationColor: '#fff' },
		{ id: ContainerStatusEnum.PendingCS, label: this._languageData.pendingCs, abbreviation: this._languageData.pendingCsAbbreviation, abbreviationBackgroundColor: '#ff9500', abbreviationColor: '#fff' },
		{ id: ContainerStatusEnum.PendingIMD, label: this._languageData.pendingImd, abbreviation: this._languageData.pendingImdAbbreviation,abbreviationBackgroundColor: '#cb5500', abbreviationColor: '#fff' },
		{ id: ContainerStatusEnum.Sent, label: this._languageData.sent, abbreviation: this._languageData.sentAbbreviation, abbreviationBackgroundColor: '#4c9158', abbreviationColor: '#fff' }];
	}

	getLayoutBasedOnAppointmentStatusId() {
		if (this.appointmentStatusId) {
			for (const entry of this._appointmentStatusArray) {
				if (entry.id === this.appointmentStatusId) {
					this.abbreviation = entry.abbreviation;
					this.abbreviationColor = entry.abbreviationColor;
					this.label = entry.label;
					this.abbreviationBackgroundColor = entry.abbreviationBackgroundColor;
				}
			}
		}
	}

	getCountFormatted() {
		if (this.count) {
			if (this.count >= 10) {
				return this.count;
			}

			return '0' + this.count;
		}

		return '00';
	}
}