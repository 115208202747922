import { Booking } from '../../../resource/dto/booking';
import { ContainerStatusEnum, AppointmentStatusEnum, AppointmentService } from '../../../resource/page/appointment/appointment.sevice';
import { Component, OnInit, Input } from '@angular/core';
import { ILanguageConfig } from '../../interface/i-language-config';

@Component({
	selector: 'app-booking-list',
	templateUrl: './booking-list.component.html',
	styleUrls: ['./booking-list.component.scss']
})
export class BookingListComponent implements OnInit, ILanguageConfig {

	@Input() booking: Booking = new Booking();
	@Input() color: string;
	@Input() appointmentStatusEnum: AppointmentStatusEnum;

	_iconDir = './../../../../../assets/icon/';
	_images = {
		arrowDirectionRight: this._iconDir + 'arrow_direction_right.svg',
		containers: this._iconDir + 'containers.svg',
		exportation: this._iconDir + 'exportation.svg',
		importation: this._iconDir + 'importation.svg',
		info: this._iconDir + 'info_appointment.svg',
		insideBL: this._iconDir + 'inside_bl.svg',
		outsideBL: this._iconDir + 'outside_bl.svg',
		placeOfDelivery: this._iconDir + 'place_of_receipt.svg',
		placeOfReceipt: this._iconDir + 'place_of_receipt.svg',
		portOfDischarge: this._iconDir + 'port_of_discharge.svg',
		portOfLoading: this._iconDir + 'port_of_loading.svg'
	};
	_languageData;

	constructor() { }

	ngOnInit() {
		this.getLanguageFile();
	}

	get containerStatusEnum() { return ContainerStatusEnum; }

	getLanguageFile() {
		this._languageData = require('./language.json').en;
	}

	insideBL() {
		// Fake
		return this.appointmentStatusEnum == AppointmentStatusEnum.PendingExport;
	}

	expo() {
		// Fake
		return this.appointmentStatusEnum == AppointmentStatusEnum.PendingExport;
	}

	getStatusColor() {
		if (this.color) {
			return this.color;
		}

		return AppointmentService.getAppointmentStatusColor(this.appointmentStatusEnum);
	}
}