import { NgModule } from '@angular/core';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { NoopAnimationsModule, BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { CoreModule } from './core/core.module';
import { BrowserModule, HAMMER_LOADER } from '@angular/platform-browser';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { HttpInterceptorService } from './core/service/http-interceptor.service';
import { MsalModule, MsalInterceptor } from '@azure/msal-angular';
import { environment } from 'src/environments/environment';
import { LocationStrategy, HashLocationStrategy } from '@angular/common';
import {NgxMaskModule} from 'ngx-mask';
import { LOCALE_ID } from '@angular/core';
import { LoaderInterceptor } from './core/interceptors/loader-interceptor';
import { LoaderComponent } from './core/component/loader/loader.component';
import { CKEditorModule } from 'ckeditor4-angular';
import { MAT_HAMMER_OPTIONS } from '@angular/material';

const isIE = window.navigator.userAgent.indexOf('MSIE ') > -1 || window.navigator.userAgent.indexOf('Trident/') > -1;

@NgModule({
	declarations: [
		AppComponent,
	],
	imports: [
		BrowserModule,
		BrowserAnimationsModule,
		AppRoutingModule,
		NoopAnimationsModule,
		CoreModule,
		CKEditorModule,
		NgxMaskModule.forRoot(),
		MsalModule.forRoot({
			auth: {
				clientId: environment.clientId,
				authority: `https://login.microsoftonline.com/${environment.tenant}`,
				redirectUri: environment.redirectUri,
				postLogoutRedirectUri : environment.redirectUri + '#/login',
			},
			cache: {
				cacheLocation: 'localStorage',
			},
		}, {
			popUp: !localStorage.getItem('isCypress'),
			consentScopes: [
				'user.read',
				'openid',
				'profile',
			],
			unprotectedResources: [],
			protectedResourceMap: [
				['https://graph.microsoft.com/v1.0/me', [
					'user.read',
					'openid',
					'profile'
					]
				]
			]
		})
	],
	providers: [
	{
		provide: MAT_HAMMER_OPTIONS,
		useValue: { cssProps: { userSelect: true } },
	},{
		provide: HTTP_INTERCEPTORS,
		useClass: HttpInterceptorService,
		multi: true
	},{
		provide: HTTP_INTERCEPTORS,
		useClass: LoaderInterceptor,
		multi: true
	}, {
		provide: HTTP_INTERCEPTORS,
		useClass: MsalInterceptor,
		multi: true
	}, {
		provide: LocationStrategy, useClass: HashLocationStrategy
	},{ provide: LOCALE_ID, useValue: "pt" }, {
		provide: HAMMER_LOADER,
		useValue: () => new Promise(() => {})
	}],
	bootstrap: [AppComponent, LoaderComponent]
})

export class AppModule { }