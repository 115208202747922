import { Directive, ElementRef, Renderer2, HostListener } from '@angular/core';

@Directive({
	selector: '[customHourFormat]'
})
export class CustomHourFormatDirective {
	constructor(private el: ElementRef, private renderer: Renderer2) {}

	@HostListener('input', ['$event']) onInput(event: Event): void {
		const inputValue: string = this.el.nativeElement.value;

		// Remove non-numerical characters
		const numericValue: string = inputValue.replace(/[^\d]/g, '');

		// Format the hour while the user types
		const formattedValue = this.formatHourWhileTyping(numericValue);

		// Set the formatted value in the input element
		this.renderer.setProperty(this.el.nativeElement, 'value', formattedValue);
	}

	private formatHourWhileTyping(value: string): string {
		// Add the hh:mm mask while the user types
		const maskedValue = value.replace(/^(\d{0,2})(\d{0,2})/, '$1:$2');

		// Get hours and minutes
		const [hours, minutes] = maskedValue.split(':').map(part => parseInt(part, 10));

		// Check if hours and minutes are within the allowed limits
		if (hours < 0 || hours > 99 || minutes < 0 || minutes > 59) {
			return '';
		}

		// Limit the minutes to 00 if the hours reach the limit
		if (hours === 99 && minutes > 0) {
			return `99:00`;
		}

		return maskedValue.endsWith(':') ? maskedValue.slice(0, -1) : maskedValue;
	}
}

@Directive({
	selector: '[customTimeFormat]'
})
export class CustomTimeFormatDirective {
	constructor(private el: ElementRef, private renderer: Renderer2) {}

	@HostListener('input', ['$event']) onInput(event: Event): void {
		const inputValue: string = this.el.nativeElement.value;

		// Remove caracteres não numéricos
		const numericValue: string = inputValue.replace(/[^\d]/g, '');

		// Formata a hora enquanto o usuário digita
		const formattedValue = this.formatTimeWhileTyping(numericValue);

		// Define o valor formatado no elemento de entrada
		this.renderer.setProperty(this.el.nativeElement, 'value', formattedValue);
	}

	private formatTimeWhileTyping(value: string): string {
		// Add the hh:mm mask while the user types
		const maskedValue = value.replace(/^(\d{0,2})(\d{0,2})/, '$1:$2');

		// Get hours and minutes
		const [hours, minutes] = maskedValue.split(':').map(part => parseInt(part, 10));

		// Check if hours and minutes are within the allowed limits
		if (hours < 0 || hours > 23 || minutes < 0 || minutes > 59) {
			return '';
		}

		// Limit the minutes to 59 if the hours reach the limit
		if (hours === 23 && minutes > 59) {
			return `23:59`;
		}

		return maskedValue.endsWith(':') ? maskedValue.slice(0, -1) : maskedValue;
	}
}
