export default {
	redirectUri: 'https://intercab-uat.alianca.com.br/',
	apiUri: 'https://api-stage.alianca.com.br/intercab',
	apiMaerskUri: 'https://api-stage.maersk.com/intercab',
	fileReference: 'none.ts',
	ambient: 'uat' as const,
	version: '1.7.0',
	consumerkey: 'kai0RMApRJ7OfFnRoTHQqR67agF5RoxF',
	instrumentationKey: '30287add-fbe6-4f4e-a51a-c04a3b7f5f94',
	azfUri: 'https://faazewrg3tanl001upf.azurewebsites.net',
	commercialUri: 'https://api-stage.alianca.com.br/intercab' + '/commercial/v1/commercial/',
	postponeUri: 'https://api-stage.alianca.com.br/intercab/postponed-validity/v1/postponed-validity/agreement/'
};
