import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import { AppModule } from './app/app.module';
import { environment } from './environments/environment';
import { executeRum } from './MopConfig/mop';
import 'hammerjs';

// Commented code found in Sprint 12
// import cssVars from 'css-vars-ponyfill';

// Commented code found in Sprint 12
// cssVars({
// 	include: 'style',
// 	onlyLegacy: false,
// 	watch: true,
// });

executeRum();

if (environment.production) {
	enableProdMode();
}

platformBrowserDynamic().bootstrapModule(AppModule).catch(err => console.error(err));