import { Injectable } from '@angular/core';
import * as LDClient from 'launchdarkly-js-client-sdk';
import { environment } from "../../../environments/environment";
import LaunchDarkly from "launchdarkly-js-client-sdk";
import { BehaviorSubject, Observable } from "rxjs";

@Injectable({
	providedIn: 'root'
})
export class LaunchDarklyService {
	private ldClient: any;
	private flagsSubject = new BehaviorSubject<any>({});
	flags$ = this.flagsSubject.asObservable();
	constructor() {
		this.initialize();
	}

	private async initialize(): Promise<void> {
		const clientSideId = environment.ldClientSideId;
		const context = {
			key: environment.ldContextKey,
			name: environment.ldContextName
		};

		this.ldClient = LaunchDarkly.initialize(clientSideId, context);

		this.ldClient.on('change', (changes: any) => {
			const updatedFlags = { ...this.flagsSubject.value };
			Object.keys(changes).forEach(flagKey => {
				updatedFlags[flagKey] = changes[flagKey].current;
			});

			this.flagsSubject.next(updatedFlags);
			console.log('Flags atualizadas:', updatedFlags);
		});

		this.ldClient.on('ready', () => {
			console.log('LaunchDarkly client ready!');
			this.flagsSubject.next(this.ldClient.allFlags());
		});

		this.ldClient.on('failed', (error) => {
			console.error('LaunchDarkly failed to initialize:', error);
		});
	}
}
