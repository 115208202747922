import {Injectable} from '@angular/core';
import {MainMenuItem} from '../dto/main-menu-item';

@Injectable({
	providedIn: 'root'
})
export class MasterService {

	constructor() {
	}

	getMenuDisplayStructure(labels): MainMenuItem[] {
		return [
			{key: 'agreement_management', label: labels.agreement_management, icon: 'agreement_management', route: 'agreement'},
			{key: 'agreement_bulk_cdt', label: labels.agreement_bulk, icon: 'agreement_bulk', route: 'https://cdt.alianca.com.br/alianca/#/bulk-copy-process'},
			{key: 'agreement_bulk_qa', label: labels.agreement_bulk, icon: 'agreement_bulk', route: 'https://stage.alianca.com.br/alianca/#/bulk-copy-process'},
			{key: 'agreement_bulk_eyt', label: labels.agreement_bulk, icon: 'agreement_bulk', route: 'https://apiazewtanli001www.azurewebsites.net/#/bulk-copy-process'},
			{key: 'agreement_bulk_uat', label: labels.agreement_bulk, icon: 'agreement_bulk', route: 'https://uat.alianca.com.br/alianca/#/bulk-copy-process'},
			{key: 'agreement_bulk', label: labels.agreement_bulk, icon: 'agreement_bulk', route: 'https://www.alianca.com.br/alianca/#/bulk-copy-process'},
			{key: 'appointment', label: labels.appointment, icon: 'appointment', route: 'appointment'},
			{key: 'appointment_list', label: labels.im_appointment, icon: 'calendar_truck', route: 'appointment/list'},
			{key: 'appointment_create', label: labels.create_appointment, icon: 'add', route: 'appointment/create'},
			{key: 'booking', label: labels.booking, icon: 'booking-icon', route: 'booking'},
			{key: 'booking_management_cdt', label: labels.booking_management, icon: 'booking-management-icon', route: 'https://cdt.alianca.com.br/alianca/#/booking'},
			{key: 'booking_management_qa', label: labels.booking_management, icon: 'booking-management-icon', route: 'https://stage.alianca.com.br/alianca/#/booking'},
			{key: 'bulk_notification_cdt', label: labels.bulk_notification, icon: 'bulk-notification-icon', route: 'https://cdt.alianca.com.br/alianca/#/booking/bulk-notification'},
			{key: 'bulk_notification_qa', label: labels.bulk_notification, icon: 'bulk-notification-icon', route: 'https://stage.alianca.com.br/alianca/#/booking/bulk-notification'},
			{key: 'cargo_rollover_cdt', label: labels.cargo_rollover, icon: 'cargo-rollover-management-icon', route: 'https://cdt.alianca.com.br/alianca/#/cargo-rollover'},
			{key: 'cargo_rollover_qa', label: labels.cargo_rollover, icon: 'cargo-rollover-management-icon', route: 'https://stage.alianca.com.br/alianca/#/cargo-rollover'},
			{key: 'cabotage', label: labels.cabotage, icon: 'cabotage', route: 'cabotage'},
			{key: 'commercial', label: labels.commercial, icon: 'commercial', route: 'commercial'},
			{key: 'commercial_plan', label: labels.commercial_plan, icon: 'commercial_plan', route: 'commercial_plan'},
			{key: 'customer', label: labels.customer, icon: 'customer', route: 'customer'},
			{key: 'customer_corporate_list', label: labels.corporate_group, icon: 'icon-corporate-group', route: 'customer/corporate/list'},
			{key: 'customer_create', label: labels.create_customer, icon: 'create_sub_menu', route: 'customer/create'},
			{key: 'customer_list', label: labels.customer_list, icon: 'list_blue', route: 'customer/list'},
			{key: 'customer_sub_group_list', label: labels.sub_group_list, icon: 'sub_group_list', route: 'customer/sub_group_list'},
			{key: 'customer_credit_condition_cdt', label: labels.customer_credit_condition, icon: 'credit_condition_icon', route: 'https://cdt.alianca.com.br/alianca/#/customer/credit-condition'},
			{key: 'customer_credit_condition_qa', label: labels.customer_credit_condition, icon: 'credit_condition_icon', route: 'https://stage.alianca.com.br/alianca/#/customer/credit-condition'},
			{key: 'customer_credit_condition_eyt', label: labels.customer_credit_condition, icon: 'credit_condition_icon', route: 'https://apiazewtanli001www.azurewebsites.net/#/customer/credit-condition'},
			{key: 'customer_credit_condition_uat', label: labels.customer_credit_condition, icon: 'credit_condition_icon', route: 'https://uat.alianca.com.br/alianca/#/customer/credit-condition'},
			{key: 'customer_credit_condition', label: labels.customer_credit_condition, icon: 'credit_condition_icon', route: 'https://www.alianca.com.br/alianca/#/customer/credit-condition'},
			{key: 'dashboard', label: labels.dashboard, icon: 'dashboard', route: 'dashboard'},
			{key: 'favorites', label: labels.favorites, icon: 'favorites', route: 'favorites'},
			{key: 'general_register', label: labels.general_register, icon: 'general_register', route: 'general_register'},
			{key: 'general_register_business_unity', label: labels.business_unity, icon: 'business_unity', route: 'general_register/business_unity'},
			{key: 'general_register_certification_list', label: labels.certification_list, icon: 'certificate', route: 'general_register/certification_list'},
			{key: 'general_register_cities_and_states_list', label: labels.cities_and_states_list, icon: 'locale', route: 'general_register/cities_and_states/list'},
			{key: 'general_register_commodity', label: labels.commodity, icon: 'commodity', route: 'general_register/commodity'},
			{key: 'general_register_company_list', label: labels.company, icon: 'operational', route: 'general_register/company_list'},
			{key: 'general_register_operational_list', label: labels.operational_list, icon: 'operational', route: 'general_register/operational_list'},
			{key: 'general_register_port_main', label: labels.port_main, icon: 'port', route: 'general_register/port_main'},
			{key: 'general_register_port_list', label: labels.ports_list, icon: 'port', route: 'general_register/port_list/list'},
			{key: 'general_register_transport_list', label: labels.transport_list, icon: 'transport', route: 'general_register/transport_list'},
			{key: 'general_register_port_terminals', label: labels.port_terminals, icon: 'list_blue', route: 'general_register/port_terminals/list'},
			{key: 'general_register_barge_and_rail_terminals', label: labels.barge_and_rail_terminals, icon: 'barge_and_rail_terminals', route: 'general_register/barge_and_rail_terminals/list'},
			{key: 'general_register_exchange_rate_list', label: labels.exchange_rate, icon: 'exchange_rate', route: 'general_register/exchange_rate_list'},
			{key: 'general_register_disclaimer_list', label: labels.disclaimers, icon: 'disclaimer', route: 'general_register/disclaimer_list'},
			{key: 'general_register_sales_rep_list_cdt', label: labels.sales_rep_list, icon: 'sales_rep_list', route: 'https://cdt.alianca.com.br/alianca/#/sales-rep'},
			{key: 'general_register_sales_rep_list_qa', label: labels.sales_rep_list, icon: 'sales_rep_list', route: 'https://stage.alianca.com.br/alianca/#/sales-rep'},
			{key: 'general_register_sales_rep_list_eyt', label: labels.sales_rep_list, icon: 'sales_rep_list', route: 'https://apiazewtanli001www.azurewebsites.net/#/sales-rep'},
			{key: 'general_register_sales_rep_list_uat', label: labels.sales_rep_list, icon: 'sales_rep_list', route: 'https://uat.alianca.com.br/alianca/#/sales-rep'},
			{key: 'general_register_sales_rep_list', label: labels.sales_rep_list, icon: 'sales_rep_list', route: 'https://www.alianca.com.br/alianca/#/sales-rep'},
			{key: 'general_register_un_number_list_cdt', label: labels.un_number_list, icon: 'numbers_group', route: 'https://cdt.alianca.com.br/alianca/#/booking-imo'},
			{key: 'general_register_un_number_list_qa', label: labels.un_number_list, icon: 'numbers_group', route: 'https://stage.alianca.com.br/alianca/#/booking-imo'},
			{key: 'intermodal', label: labels.intermodal, icon: 'intermodal', route: 'intermodal'},
			{key: 'localities', label: labels.localities_mapping, icon: 'localities_mapping', route: 'localities'},
			{key: 'localities_port', label: labels.port, icon: 'localities_port', route: 'localities/port/list'},
			{key: 'localities_regions', label: labels.regions, icon: 'localities_regions', route: 'localities/regions/list'},
			{key: 'product', label: labels.vessel_management, icon: 'product', route: 'product'},
			{key: 'product_management', label: labels.Products_Management, icon: 'Product_Management', route: 'product-management'},
			{key: 'product_management_characteristic', label: labels.Characteristics, icon: 'Characteristic', route: 'product-management/characteristics/list'},
			{key: 'costing', label: labels.costing, icon: 'costing_product', route: 'costing'},
			{key: 'ad_hoc_routes', label: labels.ad_hoc_routes, icon: 'ad_hoc_route', route: 'vessel-management/ad_hoc_routes/list'},
			{key: 'pricing', label: labels.pricing, icon: 'pricing', route: 'pricing'},
			{key: 'product_management_products', label: labels.Products, icon: 'Products', route: 'product-management/products/list'},
			{key: 'product_routes', label: labels.routes, icon: 'routes', route: 'vessel-management/routes'},
			{key: 'product_routes_preferred_routes_list', label: labels.preferred_routes, icon: 'preferred-routes', route: 'vessel-management/routes/preferred_routes/list'},
			{key: 'product_vessel_capacity', label: labels.vessel_capacity, icon: 'vessel_capacity', route: 'vessel-management/vessel_capacity'},
			{key: 'product_vessel_capacity_acr_list', label: labels.acr, icon: 'proforma', route: 'vessel-management/vessel_capacity/acr/list'},
			{key: 'product_vessel_capacity_proforma', label: labels.proforma, icon: 'proforma', route: 'vessel-management/vessel_capacity/proforma'},
			{key: 'product_vessel_capacity_proforma_list', label: labels.proforma, icon: 'proforma', route: 'vessel-management/vessel_capacity/proforma/list'},
			{key: 'product_vessel_capacity_schedule_list', label: labels.schedule, icon: 'schedule', route: 'vessel-management/vessel_capacity/schedule/list'},
			{key: 'product_vessel_capacity_vessel_list', label: labels.vessel, icon: 'vessel', route: 'vessel-management/vessel_capacity/vessel/list'},
			{key: 'wiki', label: labels.wiki, icon: 'wiki', route: 'wiki'},
		];
	}
}